<template>
  <div id="uploadImg">
    <label>
      <input
        @change="selectImg($event)"
        type="file"
        class="hide"
        name="file"
        accept="*"
        ref="file"
      />
      <slot name="uploadBtn">
        <el-button
          size="mini"
          round
          type="primary"
          @click="$refs.file.click()"
          >{{ lang_data == 1 ? "更换头像" : "Change Profile photo" }}</el-button
        >
      </slot>
    </label>
    <el-dialog
      class="menu-update-dialog"
      :title="lang_data == 1 ? '上传图片' : 'Upload pictures'"
      :visible="uploadModal.open"
      :before-close="
        (() => {
          this.uploadModal.open = false;
        }).bind(this)
      "
      width="600px"
      :close-on-click-modal="false"
    >
      <div class="modalCont">
        <Cropper
          ref="cropper"
          :img="originPic"
          style="width: 520px; height: 250px"
          :outputSize="1"
          outputType="jpeg"
          :autoCrop="true"
          :fixedBox="true"
          :autoCropWidth="120"
          :autoCropHeight="120"
          :original="true"
        ></Cropper>
        <el-button
          type="primary"
          round
          size="small"
          class="mt20"
          @click="$refs.uploadBtn.click()"
        >
          {{ lang_data == 1 ? "重新选择图片" : "Reselect File" }}
        </el-button>

        <input
          ref="uploadBtn"
          @change="selectImg($event)"
          type="file"
          class="hide"
          id="cropUploadBtn"
          name="file"
          accept="*"
        />
      </div>
      <div slot="footer" style="text-align: center">
        <el-button size="small" @click="uploadModal.open = false">{{
          lang_data == 1 ? "取消" : "Cancel"
        }}</el-button>
        <el-button size="small" type="primary" @click="confirmImg()">{{
          lang_data == 1 ? "确认" : "Confirm"
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Cropper from "vue-cropper";
export default {
  name: "uploadImg",
  components: { Cropper },
  props: ["photo", "type"],
  mounted() {
    /*监听事件,上传图片*/
    this.$on("startUpload", this.startUpload);
  },
  data() {
    return {
      uploadModal: {
        open: false,
      },
      originPic: "",
      lang_data: localStorage.lang_data || 1,
    };
  },
  methods: {
    /*选择图片*/
    selectImg(e) {
      let _this = this;
      let ipt = e.target;
      let fileReader = new FileReader(),
        files = ipt.files,
        file;

      if (!files.length) {
        return;
      }
      file = files[0];
      if (/^image\/\w+$/.test(file.type)) {
        fileReader.readAsDataURL(file);
        fileReader.onload = function () {
          _this.originPic = this.result;
          _this.uploadModal.open = true;
          ipt.value = "";
        };
      } else {
        this.$message.error(
          this.lang_data == 1
            ? "请选择图片文件"
            : "Please select a picture file"
        );
      }
    },
    /*开始裁剪*/
    confirmImg() {
      this.$refs.cropper.getCropData((base64) => {
        this.$emit("update-img", base64);
        this.uploadModal.open = false;
        // this.startUpload();
      });
    },
    /*上传图片,需要手动触发,通常根据表单的提交按钮手动触发*/
    startUpload() {
      if (this.originPic == "") {
        this.$emit("upload-img-success");
        return;
      }
      this.$refs.cropper.getCropBlob((data) => {
        this.$getuploadsign(this.type, data).then((res) => {
          if (res.res.code != 1) {
            this.$message("");
            return;
          }
          let url = res.url;
          this.uploadModal.open = false;
          this.$emit("upload-img-success", url);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
$img: "../../img/";
.hide {
  display: none;
}
.modalCont {
  padding: 20px 20px 10px !important;
  text-align: center;
}

.uploadFile {
  width: 120px;
  height: 30px;
  box-sizing: border-box;
  margin: 10px auto 0 auto;
  border: 1px solid transparent;
  transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  border-radius: 2px;
  display: block;
  cursor: pointer;
  position: relative;
  &:before {
    // width: 12px;
    // height: 12px;
    content: "重新选择图片";
    // background: url('#{$img}/upload_icon.png');
    top: 3px;
    left: 11px;
    position: absolute;
  }
}
</style>
