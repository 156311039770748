<template>
  <!-- 回款 -->
  <el-dialog
    :title="lang_data == 1 ? '回款' : 'The receivable'"
    :visible="payBackShow"
    :before-close="
      (() => {
        this.$emit('cancel');
      }).bind(this)
    "
    width="530px"
    :close-on-click-modal="false"
  >
    <div class="payBack">
      <div
        class="cont"
        v-loading="imgLoading"
        :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
      >
        <div
          class="img"
          v-loading="loading"
          :element-loading-text="
            lang_data == 1 ? '付款码已失效' : 'The payment code is invalid'
          "
          element-loading-spinner="el-icon-refresh-left"
        >
          <div class="code">
            <span v-if="!imgurl">{{
              lang_data == 1 ? "生成付款码种..." : "Generate payment code"
            }}</span>
            <img v-else class="ewm-img" :src="imgurl" alt="" />
          </div>
        </div>
        <div v-if="loading" class="mb10 mt10">
          <el-button type="primary" size="mini" @click="gitData()">{{
            lang_data == 1 ? "刷新" : "The refresh"
          }}</el-button>
        </div>
        <p
          v-if="imgurl"
          style="margin-top: 10px; color: #f56c6c; margin-bottom: 10px"
        >
          *
          {{
            lang_data == 1
              ? "请使用微信扫一扫付款,付款码有效时间5分钟,失效后点击刷新重新生成"
              : "Please Pay by Wechat, the payment code will invalid in 5 minutes, please refresh"
          }}
        </p>
      </div>
    </div>
  </el-dialog>
</template>
<script>
var set;
export default {
  props: {
    obj: Object,
    payBackShow: Boolean,
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      input: "",
      pay_no: "",
      imgurl: "",
      loading: false,
      imgLoading: false,
    };
  },

  created() {
    this.gitData();
  },
  methods: {
    gitData() {
      this.loading = false;
      clearInterval(set);
      this.$axios
        .post("/ucenter/returnedOrderSettlement", {
          order_settlement_id: this.obj.id,
        })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.imgurl = res.data.qrcode;
            this.pay_no = res.data.pay_no;
            this.imgLoading = false;
            let i = 0;
            set = setInterval(() => {
              i++;
              if (i >= res.data.expire_time / 3) {
                this.loading = true;
                clearInterval(set);
              } else {
                this.getQuery();
              }
            }, 3000);
          }
        });
    },
    cancel() {
      this.$emit("cancel");
      clearInterval(set);
    },
    getQuery() {
      this.$axios
        .post("/ucenter/getPayStatus", {
          pay_no: this.pay_no,
        })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            if (res.data && res.data.status.value == 2) {
              this.$emit("cancel", res.data);
              this.$message.success(
                this.lang_data == 1 ? "回款成功" : "The receivable success"
              );
              clearInterval(set);
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.payBack {
  text-align: center;
  .cont {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      width: 200px;
      height: 200px;
      border: 1px solid #e6e6e6;
      padding: 5px;
      border-radius: 3px;
      box-sizing: border-box;
      margin: 0 0 10px;
      flex: none;
      img {
        width: 188px;
      }
      .code {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999;
      }
    }
  }
}
</style>
