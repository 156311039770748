<template>
  <!-- 充值 -->
  <el-dialog
    class="menu-update-dialog"
    :title="lang_data == 1 ? '充值预付款' : 'Top up advance payment'"
    :visible="rechargePrepaymentShow"
    :before-close="cancel"
    width="500px"
    :close-on-click-modal="false"
  >
    <div class="rechargePrepayment">
      <div
        class="cont"
        v-loading="imgLoading"
        :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
      >
        <div class="top">
          <el-input
            v-model.trim="input"
            onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
            :placeholder="lang_data == 1 ? '请输入金额' : 'Please Enter'"
            style="width: 200px; margin-right: 10px"
            size="small"
            clearable
          ></el-input>
          <el-button type="primary" size="small" @click="codeClick">{{
            lang_data == 1 ? "生成付款码" : "Production payment code"
          }}</el-button>
        </div>
        <div
          class="img"
          v-loading="loading"
          :element-loading-text="
            lang_data == 1 ? '付款码已失效' : 'The payment code is invalid'
          "
          element-loading-spinner="el-icon-refresh-left"
        >
          <div class="code">
            <span v-if="!imgurl">{{
              lang_data == 1
                ? "输入金额点击生成付款码"
                : "Input Amount and click to create payment code"
            }}</span>
            <img v-else class="ewm-img" :src="imgurl" alt="" />
          </div>
        </div>
        <p v-if="imgurl" style="color: #f56c6c; margin-bottom: 10px">
          *
          {{
            lang_data == 1
              ? "请使用微信扫一扫付款,付款码有效时间5分钟,失效后点击重新生成"
              : "Please Pay by Wechat, the payment code will invalid in 5 minutes, please refresh"
          }}
        </p>
      </div>
    </div>
    <div slot="footer" style="text-align: center">
      <el-button size="small" @click="cancel()">{{
        lang_data == 1 ? "关闭" : "Cancel"
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
var set;
export default {
  props: {
    rechargePrepaymentShow: [Boolean],
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      input: "",
      imgurl: "",
      pay_no: "",
      loading: false,
      imgLoading: false,
    };
  },
  watch: {
    input(val) {
      if (!val) {
        this.imgurl = "";
      }
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      clearInterval(set);
    },
    codeClick() {
      clearInterval(set);
      if (this.input && this.input != 0) {
        this.loading = false;
        this.imgLoading = true;
        this.$axios
          .post("/ucenter/rechargeAdvancePrice", {
            recharge_advance: this.input,
          })
          .then((res) => {
            if (res.code != 1) {
              this.$message.error(res.msg);
            } else {
              this.imgurl = res.data.qrcode;
              this.pay_no = res.data.pay_no;
              this.imgLoading = false;
              let i = 0;
              set = setInterval(() => {
                i++;
                if (i >= res.data.expire_time / 3) {
                  this.loading = true;
                  clearInterval(set);
                } else {
                  this.getQuery();
                }
              }, 3000);
            }
          });
      } else {
        this.$message.closeAll();
        this.$message.error(
          this.lang_data == 1
            ? "请输入大于0的金额"
            : "Please enter an amount greater than 0"
        );
      }
    },
    getQuery() {
      this.$axios
        .post("/ucenter/getPayStatus", {
          pay_no: this.pay_no,
        })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            if (res.data) {
              this.$emit("cancel", res.data);
              this.$message.success(
                this.lang_data == 1 ? "充值成功" : "Top-up success"
              );
              clearInterval(set);
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.rechargePrepayment {
  .cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      width: 200px;
      height: 200px;
      border: 1px solid #e6e6e6;
      padding: 5px;
      border-radius: 3px;
      box-sizing: border-box;
      margin: 20px 0;
      flex: none;
      .code {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
