import { render, staticRenderFns } from "./rechargePrepayment.vue?vue&type=template&id=963d774a&scoped=true&"
import script from "./rechargePrepayment.vue?vue&type=script&lang=js&"
export * from "./rechargePrepayment.vue?vue&type=script&lang=js&"
import style0 from "./rechargePrepayment.vue?vue&type=style&index=0&id=963d774a&prod&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "963d774a",
  null
  
)

export default component.exports