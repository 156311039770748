<template>
  <div id="uploadImg">
    <label>
      <input
        @change="selectImg($event)"
        type="file"
        class="hide"
        name="file"
        accept="*"
      />
      <slot name="uploadBtn">
        <span style="height: 100%" class="line-button">{{
          lang_data == 1 ? "上传图片" : "To upload pictures"
        }}</span>
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "uploadNoCropper",
  components: {},
  props: ["photo", "type"],
  mounted() {
    /*监听事件,上传图片*/
    this.$on("startUpload", this.startUpload);
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      uploadModal: {
        open: false,
      },
      originPic: "",
      uploadPic: "",
    };
  },
  methods: {
    selectImg(e) {
      let _this = this;
      let ipt = e.target;
      let fileReader = new FileReader(),
        files = ipt.files,
        file;

      if (!files.length) {
        return;
      }
      file = files[0];
      if (/^image\/\w+$/.test(file.type)) {
        fileReader.readAsDataURL(file);
        fileReader.onload = function () {
          _this.originPic = this.result;
          _this.uploadPic = file;
          _this.uploadModal.open = true;
          ipt.value = "";
          _this.$emit("update-img", _this.originPic, file);
        };
      } else {
        this.$message.error(
          this.lang_data == 1 ? "请选择图片文件" : "Please select pictures"
        );
      }
    },
    /*上传图片,需要手动触发,通常根据表单的提交按钮手动触发*/
    startUpload() {
      if (this.originPic == "") {
        this.$emit("upload-img-success");
        return;
      }
      this.$getuploadsign(this.type, this.uploadPic).then((res) => {
        if (res.res.code != 1) {
          this.$message("");
          return;
        }
        let url = res.url;
        this.uploadModal.open = false;
        this.$emit("upload-img-success", url);
      });
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
$img: "../../img/";
.hide {
  display: none;
}
.modalCont {
  padding: 20px 20px 10px !important;
}

.uploadFile {
  width: 90px;
  height: 30px;
  box-sizing: border-box;
  margin: 10px auto 0 auto;
  border: 1px solid transparent;
  transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  border-radius: 2px;
  display: block;
  cursor: pointer;
  position: relative;
  &:before {
    // width: 12px;
    // height: 12px;
    content: "重新选择文件";
    // background: url('#{$img}/upload_icon.png');
    top: 5px;
    left: 9px;
    position: absolute;
  }
}

.uploadFileBtn {
  background: #6686ea;
  border-color: #6686ea;
  color: #fff;
  @extend .uploadFile;
}

.uploadFileBtn:hover {
  background: #5377ea;
  border-color: #5377ea;
  color: #fff;
}

.uploadFileBtn:active {
  background: #486fe9;
  border-color: #486fe9;
  color: #fff;
}
</style>
