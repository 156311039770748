<template>
  <!-- 结算单查看 -->
  <el-dialog
    :title="lang_data == 1 ? '结算单详情' : 'Billing Detail'"
    :visible="true"
    :before-close="cancel"
    width="1000px"
    :close-on-click-modal="false"
  >
    <div class="rechargePrepayment">
      <div
        class="cont"
        v-loading="loading"
        :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
      >
        <div class="title">
          <i class="el-icon-tickets mr5"></i
          >{{ lang_data == 1 ? "基本信息" : "The basic information" }}
        </div>
        <div class="basicInformation information">
          <p>
            <span>{{ lang_data == 1 ? "结算单编号：" : "Billing No.：" }}</span
            >{{ dataDetails.order_no }}
          </p>
          <p>
            <span>{{ lang_data == 1 ? "当前状态：" : "Current Status：" }}</span
            >{{ dataDetails.status.label }}
          </p>
          <p>
            <span>{{ lang_data == 1 ? "诊所编号：" : "Clinic number：" }}</span
            >{{ dataDetails.clinic_no.toString() }}
          </p>
          <p>
            <span>{{ lang_data == 1 ? "诊所名称：" : "Clinic name：" }}</span
            >{{ dataDetails.clinic_name.toString() }}
          </p>
          <p>
            <span>{{ lang_data == 1 ? "创建时间：" : "Creation time：" }}</span>
            {{
              $moment(dataDetails.create_time * 1000).format("YYYY-MM-DD HH:MM")
            }}
          </p>
        </div>
        <div class="title">
          <i class="el-icon-document-checked mr5"></i
          >{{ lang_data == 1 ? "结算单信息" : "Billing Information" }}
        </div>
        <div class="statementInformation information">
          <el-table :data="dataDetails.order_list" style="width: 100%" border>
            <el-table-column
              prop="order_no"
              :label="lang_data == 1 ? '订单编号' : 'The order no.'"
            >
            </el-table-column>
            <el-table-column
              prop="project_name"
              :label="lang_data == 1 ? '项目' : 'Item'"
            >
            </el-table-column>
            <el-table-column
              prop="settlement_status_show.label"
              :label="lang_data == 1 ? '核销状态' : 'Cancel after verification'"
              width="90"
            >
            </el-table-column>
            <el-table-column
              prop="patient_name"
              :label="lang_data == 1 ? '患者姓名' : 'Patient'"
              width="90"
            >
            </el-table-column>
            <el-table-column
              prop="doctor_name"
              :label="lang_data == 1 ? '所属医生' : 'Doctor'"
              width="90"
            >
            </el-table-column>
            <el-table-column
              prop="order_type_show.label"
              :label="lang_data == 1 ? '订单类型' : 'Order type'"
              width="90"
            >
            </el-table-column>
            <el-table-column
              prop="order_amount"
              :label="lang_data == 1 ? '原价' : 'The original price'"
              width="90"
            >
            </el-table-column>
            <el-table-column
              prop="discounts_price"
              :label="lang_data == 1 ? '优惠金额' : 'Discount'"
              width="90"
            >
            </el-table-column>
            <el-table-column
              prop="receivable_price"
              :label="lang_data == 1 ? '应回款金额' : 'Receivable'"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="add_time"
              :label="lang_data == 1 ? '创建时间' : 'Create time'"
            >
              <template slot-scope="{ row }">
                <span>
                  {{
                    $moment(row.add_time * 1000).format("YYYY-MM-DD HH:mm:ss")
                  }}
                </span>
              </template>
            </el-table-column>
          </el-table>
          <p class="mt20">
            <span>{{
              lang_data == 1 ? "订单数量：" : "Quantity of order："
            }}</span
            >{{ dataDetails.order_list.length }}
          </p>
          <p>
            <span>{{ lang_data == 1 ? "合计总价：" : "Total price：" }}</span
            >{{ dataDetails.receivable_price }}
          </p>
          <p>
            <span>{{ lang_data == 1 ? "优惠金额：" : "Discount：" }}</span
            ><i style="color: #f56c6c; font-style: normal">{{
              dataDetails.discounts_price
            }}</i>
          </p>
          <p>
            <span>{{ lang_data == 1 ? "应回款金额：" : "Receivable：" }}</span
            >{{ dataDetails.reality_receivable_price }}
          </p>
          <p>
            <span>{{ lang_data == 1 ? "已回款金额：" : "paid：" }}</span
            >{{ dataDetails.received_price }}
          </p>
          <p>
            <span>{{ lang_data == 1 ? "未回款金额：" : "unpaid：" }}</span
            >{{
              (
                Number(dataDetails.unreceived_price) +
                Number(dataDetails.paymenting_amount)
              ).toFixed(2)
            }}
          </p>
          <div class="title mt20 mb20">
            <i class="el-icon-edit-outline mr5"></i
            >{{ lang_data == 1 ? "回款记录" : "Payment record" }}
          </div>
          <div class="timeline" v-if="dataDetails.pay_list.length > 0">
            <el-timeline>
              <el-timeline-item
                :timestamp="
                  $moment(item.create_time * 1000).format('YYYY-MM-DD')
                "
                placement="top"
                v-for="(item, i) in dataDetails.pay_list"
                :key="i"
              >
                <el-card>
                  <div class="timeContent">
                    <p>
                      <span>{{
                        lang_data == 1 ? "回款金额：" : "payment amount："
                      }}</span
                      >{{
                        (
                          Number(item.pay_money) + Number(item.advance_payment)
                        ).toFixed(2)
                      }}
                    </p>
                    <p>
                      <span>{{
                        lang_data == 1 ? "支付方式：" : "Payment Method："
                      }}</span
                      >{{ item.pay_type.label }}
                    </p>

                    <p>
                      <span>{{
                        lang_data == 1 ? "回款时间：" : "Payment Time："
                      }}</span>
                      {{
                        $moment(item.create_time * 1000).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      }}
                    </p>
                    <p>
                      <span>{{
                        lang_data == 1 ? "支付状态：" : "Payment status："
                      }}</span
                      >{{ item.status.label }}
                    </p>
                    <div v-if="item.invalid_status.value == 2">
                      <el-divider></el-divider>
                      <p>
                        <span>{{
                          lang_data == 1 ? "作废原因：" : "Reason of deletion："
                        }}</span
                        >{{ item.invalid_reason }}
                      </p>
                      <p>
                        <span>{{
                          lang_data == 1 ? "操作时间：" : "Process time："
                        }}</span>
                        {{
                          $moment(item.invalid_time * 1000).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        }}
                      </p>
                    </div>
                    <div class="state" v-if="item.invalid_status.value == 1">
                      {{ lang_data == 1 ? "有效" : "valid" }}
                    </div>
                    <div
                      class="state state1"
                      v-if="item.invalid_status.value == 2"
                    >
                      {{ lang_data == 1 ? "已作废" : "deleted" }}
                    </div>
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
          <div v-else style="padding: 10px 0; color: #999; margin-bottom: 20px">
            {{ lang_data == 1 ? "暂无数据" : "No Data" }}
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" style="text-align: center">
      <el-button size="small" @click="cancel">{{
        lang_data == 1 ? "关闭" : "Shut down"
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    obj: Object,
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      input: "",
      loading: false,
      tableData: [],
      activeName: "1",
      dataDetails: {
        status: {},
        settlement_discounts: [],
        clinic_name: [],
        sales_staff_name: [],
        clinic_no: [],
        order_list: [],
        order_discounts: [],
        pay_list: [],
        invalid_status: {},
      },
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    getDetails() {
      this.loading = true;
      this.$axios
        .post(`/ucenter/getOrderSettlementDetail`, {
          order_settlement_id: this.obj.id,
        })
        .then((res) => {
          if (res.code == "1") {
            this.dataDetails = res.data;
          } else {
            this.$message.error(res.msg);
          }

          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.rechargePrepayment {
  .cont {
    .title {
      height: 38px;
      line-height: 38px;
      font-size: 13px;
      color: #666;
      text-indent: 12px;
      background-color: #f6f6f6;
      position: relative;
      font-weight: 600;
      color: #333;
      i {
        font-size: 13px;
      }
    }
    .information {
      margin: 20px 0;
      p {
        line-height: 25px;
        font-weight: 600;
        span {
          color: #999;
          width: 150px;
          display: inline-block;
          text-align: right;
          font-weight: 500;
        }
      }
      .timeContent {
        p {
          font-weight: 500;
        }
      }
    }
    .statementInformation {
      .timeline {
        margin-top: 10px;
        .timeContent {
          position: relative;
          overflow: hidden;
          padding: 20px;
          .state {
            width: 200px;
            position: absolute;
            padding: 10px 0;
            border-top: 1px solid #e6e6e6;
            border-bottom: 1px solid #e6e6e6;
            text-align: center;
            transform: rotate(45deg);
            transform-origin: 137% 118%;
            right: 0;
            background: #f8f8f8;
          }
          .state1 {
            color: #f56c6c;
            transform-origin: 197% 1%;
          }

          p {
            line-height: 25px;
            span {
              color: #999;
              width: 100px;
            }
          }
          .bottom {
            position: absolute;
            bottom: 15px;
            right: 15px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.rechargePrepayment {
  .el-timeline-item__timestamp {
    font-weight: 600;
    color: #6da7ff;
    font-size: 16px;
  }
  .el-timeline-item__node--normal {
    background: #6da7ff;
  }
  .el-timeline-item__timestamp.is-top {
    padding-top: 0;
  }
  .el-card__body {
    padding: 0;
  }
  .el-timeline-item__tail {
    left: 6px;
  }
  .el-timeline-item__node--normal {
    left: 1px;
  }
  .el-timeline-item__wrapper {
    padding-left: 30px;
  }
}
</style>
