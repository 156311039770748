<template>
  <div class="settingWrap">
    <div>
      <div class="loginSetting">
        <ul>
          <li class="c333">
            {{ lang_data == 1 ? "登录密码" : "Login Password" }}
          </li>
          <li>
            <span
              >{{
                lang_data == 1
                  ? "安全性高的密码可以使帐号更安全。建议您定期更换密码，"
                  : "A strong password can make your account more secure. It is recommended that you change your password regularly,"
              }}<br />
              {{
                lang_data == 1
                  ? "设置一个包含字母，符号或数字中至少两项且长度超过6位的密码。"
                  : "Set a password that contains at least two of letters, symbols or numbers and is longer than 6 characters."
              }}</span
            >
          </li>
          <li>
            <el-button type="primary" round size="mini" @click="pwdStatusShow = true">{{
              lang_data == 1 ? "修改密码" : "Change password"
            }}</el-button>
          </li>
        </ul>
      </div>
    </div>
    <Pwd
      v-if="pwdStatusShow"
      :pwdStatusShow="pwdStatusShow"
      @cancel="pwdStatusShow = false"
      :doctor_info="info.doctor_info"
    ></Pwd>
  </div>
</template>
<script>
import Pwd from "./components/pwd";
export default {
  name: "setting",
  components: { Pwd },
  mounted() {},
  props: ["info"],
  data() {
    return {
      loading: true,
      moblieStatus: false,
      pwdStatusShow: false,
      lang_data: localStorage.lang_data || 1,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.settingWrap {
  height: 100%;
  font-size: 14px;
  .loginInfo {
    height: 150px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    & > div:first-child {
      width: 150px;
      padding-left: 5px;
    }
  }
  .loginSetting {
    padding: 50px 70px;
    color: #666;
    li {
      margin-bottom: 20px;
    }
  }
}
</style>

