<template>
  <el-dialog
    :title="lang_data == 1 ? '修改手机号码' : 'Change Cellphone Number'"
    :visible.sync="moblieStatus"
    width="500px"
    :before-close="cancel"
    :close-on-click-modal="false"
  >
    <div class="mwCont">
      <div v-if="step == 1" class="modalCont">
        <el-form
          ref="formvalue"
          @submit.native.prevent
          :model="formvalue"
          :rules="Rules"
        >
          <div class="step">
            <div class="step1">
              <a class="act">{{
                lang_data == 1 ? "验证身份" : "Authenticate"
              }}</a
              ><a>{{ lang_data == 1 ? "修改手机" : "Modify the phone" }}</a>
            </div>
            <div class="step2"><a class="act">&nbsp;</a><a>&nbsp;</a></div>
          </div>
          <div class="moblieTips">
            {{ lang_data == 1 ? "手机号码：" : "Phone"
            }}<label>{{ mobile }}</label>
          </div>

          <el-form-item label="" prop="code">
            <el-input
              v-model="formvalue.code"
              size="small"
              type="text"
              style="width: 220px"
              :placeholder="
                lang_data == 1 ? '请输入验证码' : 'Please enter verification'
              "
            />
            <el-button
              id="getCode"
              class="ml10"
              style="width: 130px"
              size="small"
              :disabled="codeIndex != 60"
              @click="getMobileCode"
              >{{ codeName }}</el-button
            >
          </el-form-item>
        </el-form>

        <el-button
          type="primary"
          size="small"
          style="width: 100%"
          round
          @click="checkChangeMobileCode('formvalue')"
        >
          {{ lang_data == 1 ? "下一步" : "The next step" }}
        </el-button>
      </div>
      <div v-if="step == 2" class="modalCont">
        <el-form
          ref="formvalue1"
          @submit.native.prevent
          :model="formvalue1"
          :rules="Rules"
        >
          <div class="step">
            <div class="step1">
              <a class="act">{{
                lang_data == 1 ? "验证身份" : "Authenticate"
              }}</a
              ><a>{{ lang_data == 1 ? "修改手机" : "Modify the phone" }}</a>
            </div>
            <div class="step2">
              <a class="act">&nbsp;</a><a class="act">&nbsp;</a>
            </div>
          </div>

          <el-form-item label="" prop="phone" class="mt20">
            <el-input
              v-model="formvalue1.phone"
              size="small"
              type="text"
              :placeholder="
                lang_data == 1
                  ? '请输入新手机号'
                  : 'Please enter your new phone'
              "
            />
          </el-form-item>

          <el-form-item label="" prop="newCode" class="flex">
            <el-input
              v-model="formvalue1.newCode"
              size="small"
              type="text"
              style="width: 220px"
              :placeholder="
                lang_data == 1 ? '请输入验证码' : 'Please enter verification'
              "
            />
            <el-button
              id="getCode1"
              size="small"
              class="ml10"
              style="width: 130px"
              :disabled="codeIndex != 60"
              @click="getNewMobileCode"
              >{{ codeName1 }}</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              style="width: 100%"
              round
              @click="changeMobile('formvalue1')"
            >
              {{ lang_data == 1 ? "确认" : "confirm" }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="step == 3" class="modalCont">
        <div class="moblieComplete">
          <el-result
            icon="success"
            title="修改手机号成功"
            :subTitle="
              (lang_data == 1
                ? '您的登录账号已修改为'
                : 'Change the login account to ') + formvalue1.phone
            "
          >
            <template slot="extra">
              <el-button type="primary" size="medium" @click="okEvent"
                >重新登录</el-button
              >
            </template>
          </el-result>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
let time;
export default {
  name: "moblie",
  props: ["mobile", "moblieStatus"],

  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      step: null,
      phone: "",
      code: "",
      newCode: "",
      codeIndex: 60,
      codeName: localStorage.lang_data == 1 ? "获取验证码" : "Access to verify",
      codeName1:
        localStorage.lang_data == 1 ? "获取验证码" : "Access to verify",
      formvalue: {
        code: "",
      },
      formvalue1: {
        newCode: "",
        phone: "",
      },
      Rules: {
        code: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请输入验证码"
                : "Please enter verification",
            trigger: "blur",
          },
        ],
        newCode: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请输入验证码"
                : "Please enter verification",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "手机号码不能为空"
                : "The phone cannot be empty",
            trigger: "blur",
          },
          {
            // pattern: reg.phone,
            pattern: "",
            message:
              localStorage.lang_data == 1
                ? "请输入正确的手机号码"
                : "Please enter the correct mobile phone number",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    if (this.mobile) {
      this.step = 1;
    } else {
      this.step = 2;
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      if (!localStorage.token) {
        this.okEvent();
      }
    },
    getMobileCode() {
      //旧号码获取验证码
      if (this.codeIndex == 60) {
        this.codeIndex--;
        this.$axios.get("/changemobilecode").then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg);
            time = setInterval(() => {
              this.codeName = "重新发送（" + this.codeIndex + "）";
              this.codeIndex--;
              if (this.codeIndex == 0) {
                clearInterval(time);
                this.codeName = "重新发送";
                this.codeIndex = 60;
              }
            }, 1000);
          } else {
            this.$message.error(res.msg);
            this.codeIndex = 60;
          }
        });
      }
    },
    okEvent() {
      this.$router.push("/login");
    },
    getNewMobileCode() {
      //新号码获取验证码
      if (this.codeIndex == 60) {
        this.codeIndex--;
        var params = {
          mobile: this.formvalue1.phone,
        };
        this.$axios.get("/newmobilecode", { params }).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg);
            time = setInterval(() => {
              this.codeName1 = "重新发送（" + this.codeIndex + "）";
              this.codeIndex--;
              if (this.codeIndex == 0) {
                clearInterval(time);
                this.codeName1 = "重新发送";
                this.codeIndex = 60;
              }
            }, 1000);
          } else {
            this.$message.error(res.msg);
            this.codeIndex = 60;
          }
        });
      }
    },
    checkChangeMobileCode(info) {
      //更换手机号码验证码确认
      this.$refs[info].validate((valid) => {
        if (valid) {
          var params = {
            smscode: this.formvalue.code,
          };
          this.$axios
            .post("/ucenter/checkchangemobilecode", params)
            .then((res) => {
              if (res.code != 1) {
                this.$message.error(res.msg);
                return;
              } else {
                clearInterval(time);
                this.step++;
                this.codeIndex = 60;
              }
            });
        }
      });
    },
    changeMobile(info) {
      //更换手机号码
      this.$refs[info].validate((valid) => {
        if (valid) {
          var params = {
            mobile: this.formvalue1.phone,
            newmobilecode: this.formvalue1.newCode,
            mobilecode: this.formvalue.code,
          };
          this.$axios.post("/ucenter/changemobile", params).then((res) => {
            if (res.code != 1) {
              this.$message.error(res.msg);
              return;
            } else {
              this.step++;
              this.$message.success(res.msg);
              localStorage.removeItem("userData");
              this.$store.dispatch("clearLoginInfo");
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.modalCont {
  padding: 0px 50px 20px;
  overflow: hidden;
}
#getCode:disabled,
#getCode1:disabled {
  background: #eeeded;
  border-color: #eeeded;
  color: #b1b1b1;
}
.w130 {
  width: 130px;
}
.step {
  width: 100%;
  overflow: hidden;
  a {
    cursor: default;
    &:hover {
      cursor: default;
    }
  }
  .step1 {
    width: 100%;
    height: 30px;
    line-height: 30px;
    float: left;
    font-size: 13px;
    color: #333333;
    a {
      width: 50%;
      float: left;
      &:hover {
        text-decoration: none;
      }
      &:last-child {
        text-align: right;
      }
      &.act {
      }
    }
  }
  .step2 {
    width: calc(100% - 36px);
    height: 3px;
    background: #dbdbdb;
    float: left;
    position: relative;
    margin: 10px 18px 20px 18px;
    a {
      width: 50%;
      height: 3px;
      float: left;
      &:hover {
        text-decoration: none;
      }
      &:first-child {
        &:before {
          width: 10px;
          height: 10px;
          background: #999;
          content: "";
          top: -7px;
          left: -18px;
          display: inline-block;
          position: absolute;
          border-radius: 50%;
          border: 5px solid #e0e0e0;
        }
      }
      &:last-child {
        &:before {
          width: 10px;
          height: 10px;
          background: #999;
          content: "";
          top: -7px;
          right: -18px;
          display: inline-block;
          position: absolute;
          border-radius: 50%;
          border: 5px solid #e0e0e0;
        }
      }
      &.act {
        background: #009ae3;
        &:before {
          background: #009ae3;
          border-color: #c6c9f4;
        }
      }
    }
  }
}
.moblieTips {
  width: 100%;
  height: 30px;
  line-height: 30px;
  float: left;
  margin-top: 20px;
  label {
    color: #418fff;
  }
}

.moblieComplete {
  width: 100%;
  height: 200px;
  float: left;
  background: url(/static/img/modifySuccess.png) no-repeat center 0;
  text-align: center;
  p:first-child {
    font-size: 18px;
    color: #333;
    line-height: 40px;
    margin-top: 80px;
    padding-left: 10px;
  }
  p:last-child {
    label {
      color: #409eff;
      font-size: 16px;
      margin-left: 10px;
    }
  }
}
</style>
