<template>
  <div class="citySelect">
    <el-select
      v-model="province"
      transfer
      label-in-value
      @change="provinceSelect"
      :placeholder="lang_data == 1 ? '请选择' : 'Please choose'"
      size="small"
    >
      <el-option
        v-for="item in provinceList"
        :value="item.key"
        :label="item.value"
        :key="item.key"
      ></el-option>
    </el-select>
    <el-select
      class="city ml10"
      :disabled="province == 0"
      v-model="city"
      transfer
      label-in-value
      @change="citySelect"
      :placeholder="lang_data == 1 ? '请选择' : 'Please choose'"
      size="small"
    >
      <el-option
        v-for="item in cityList"
        :value="item.key"
        :key="item.key"
        :label="item.value"
      ></el-option>
    </el-select>
    <el-select
      class="county ml10"
      :disabled="city == 0"
      v-model="county"
      transfer
      label-in-value
      @change="countySelect"
      :placeholder="lang_data == 1 ? '请选择' : 'Please choose'"
      size="small"
    >
      <el-option
        v-for="item in countyList"
        :value="item.key"
        :key="item.key"
        :label="item.value"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  name: "citySelect",
  mounted() {
    this.init(1);
  },
  props: ["province1", "city1", "county1"],
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      province: 0,
      city: 0,
      county: 0,
      provinceList: [],
      cityList: [],
      countyList: [],
      jsonData: [],
      chinaMap: {},
    };
  },
  watch: {},
  methods: {
    init(parent_id) {
      this.getMap(parent_id).then((res) => {
        if (!res) {
          return;
        }
        this.provinceList = this.tool(res, 1);
        this.province = this.province1.key;
        // this.city = this.city1;
        // this.county = this.county1;
      });
      this.getMap(this.province1.key).then((res) => {
        this.cityList = this.tool(res, this.province1.key);
        this.city = this.city1.key;
      });
      this.getMap(this.city1.key).then((res) => {
        this.countyList = this.tool(res, this.city1.key);
        this.county = this.county1.key;
      });
    },
    getMap(parent_id) {
      return new Promise((resolve) => {
        let params = {
          parent_id: parent_id,
        };
        this.$axios.get("/regional", { params }).then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          }
          resolve(res.data);
        });
      });
    },
    tool(data, parentid) {
      var arr = [],
        arr1 = [
          { key: 0, value: this.lang_data == 1 ? "请选择" : "Please choose" },
        ];
      for (let n in data) {
        let v = data[n];
        if (v.parentid == parentid) {
          arr.push(v);
        }
      }
      arr.forEach(function (v) {
        arr1.push({ key: v.id, value: v.name });
      }, this);

      return arr1;
    },
    findValue(key) {
      let data = this.jsonData;
      let name = "";
      for (let n in data) {
        let v = data[n];
        if (v.id == key) {
          name = v.name;
          break;
        }
      }
      return name;
    },
    provinceSelect(key) {
      if (key == "" || key == 0) {
        return;
      }
      let val = { value: key };
      this.getMap(val.value).then((res) => {
        this.cityList = this.tool(res, val.value);
        this.countyList = this.tool([], undefined);
        this.county = 0;
        this.city = 0;
      });
      // this.cityList = this.tool(this.jsonData, val.value);
      // this.countyList = this.tool(this.jsonData, undefined);
      val.key = "province";
      if (val.label == "" || val.label == undefined) {
        val.label = this.findValue(val.value);
      }
      this.$emit("city-select-change", val);
    },
    citySelect(key) {
      if (key == "" || key == 0) {
        return;
      }
      let val = { value: key };
      val.key = "city";
      this.getMap(val.value).then((res) => {
        this.countyList = this.tool(res, val.value);
        this.county = 0;
      });
      if (val.label == "" || val.label == undefined) {
        val.label = this.findValue(val.value);
      }
      this.$emit("city-select-change", val);
    },
    countySelect(key) {
      if (key == "" || key == 0) {
        return;
      }
      let val = { value: key };
      val.key = "county";
      if (val.label == "" || val.label == undefined) {
        val.label = this.findValue(val.value);
      }
      this.$emit("city-select-change", val);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
.citySelect {
  width: 100%;
  display: flex;
  .ivu-select {
    flex: 1;
  }
}
.ivu-select.city,
.ivu-select.county {
  margin-left: -1px;
}
.ivu-select-visible {
  position: relative;
  z-index: 2;
}
</style>
